import { Typography } from "antd"
import { FC, ReactComponentElement, useEffect, useState } from "react"
import "./index.less"

const NoInternetConnection: FC<{ children: ReactComponentElement<any> }> = props => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true)

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine)
  }, [])

  // event listeners to update the state
  window.addEventListener("online", () => {
    setOnline(true)
  })

  window.addEventListener("offline", () => {
    setOnline(false)
  })

  // if user is online, return the child component else return a custom component
  if (isOnline) {
    return props.children
  } else {
    return (
      <div className={"no-connect-bg"}>
        <div className={"wrapper"}>
          <div className={"image-wrapper"}>
            <img src={"/images/NoInternet.png"} alt={""} />
          </div>
          <h1>No Internet</h1>
          <Typography.Paragraph>Please check your internet connection and try again.</Typography.Paragraph>
        </div>
      </div>
    )
  }
}

export default NoInternetConnection
