import { Button, Col, Modal, Row } from "antd"
import React, { FC, useRef, useState } from "react"
import { useVideoContext } from "src/context/VideoContext"
import { useUpdateLeadMutation } from "../../graphql"
import { useCustomNotification } from "../../hooks/useCustomNotification"
import { InquireModal } from "./InquireModal"
import "./index.less"

const VideoModal: FC = () => {
  const [inquireModal, setInquireModal] = useState(false)
  const { link, isModalOpen, leadId, leadName, setIsModalOpen } = useVideoContext()
  const videoRef = useRef(null)
  const [setLeadStatus] = useUpdateLeadMutation()
  const { openNotification } = useCustomNotification()

  const handleInquire = () => {
    setIsModalOpen(false)
    setInquireModal(true)
  }

  const handleFinishVideoReview = (status: InputMaybe<EnumLeadStatus>) => {
    setLeadStatus({
      variables: {
        id: leadId,
        input: {
          status: status,
        },
      },
    })
      .then(() => setIsModalOpen(false))
      .catch(e => openNotification(e.message, true))
  }
  return (
    <React.Fragment>
      <Modal
        open={isModalOpen}
        width={1200}
        title={`Video ${leadName}`}
        onCancel={() => setIsModalOpen(false)}
        // style={{ top: 48 }}
        centered={true}
        className={"video-modal"}
        footer={
          <Row gutter={16} justify={"end"}>
            <Col>
              <Button shape={"round"} size={"large"} danger onClick={() => handleFinishVideoReview("Declined")}>
                <span style={{ color: "#2E2E48" }}>Reject</span>
              </Button>
            </Col>
            <Col>
              <Button shape={"round"} size={"large"} type={"default"} onClick={() => handleInquire()}>
                Inquire
              </Button>
            </Col>
            <Col>
              <Button
                shape={"round"}
                size={"large"}
                type={"default"}
                onClick={() => handleFinishVideoReview("Approved")}
              >
                Shortlist
              </Button>
            </Col>
            <Col>
              <Button
                shape={"round"}
                size={"large"}
                type={"primary"}
                onClick={() => handleFinishVideoReview("Approved")}
              >
                Approve
              </Button>
            </Col>
          </Row>
        }
      >
        <div style={{ textAlign: "center" }}>
          <video
            src={link}
            controls
            ref={videoRef}
            style={{
              width: "auto",
              height: "100%",
              maxHeight: 560,
              objectFit: "contain",
              display: "inline-block",
              margin: "0 auto",
            }}
          />
        </div>
      </Modal>
      <InquireModal open={inquireModal} setIsOpen={setInquireModal} />
    </React.Fragment>
  )
}

export { VideoModal }
