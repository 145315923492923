import { ThemeConfig } from "antd/es/config-provider"

const theme: ThemeConfig = {
  inherit: false,
  token: {
    fontFamily: "Outfit",
    colorPrimary: "#F0BC9A",
    colorError: "#B83C33",
    colorText: "#161633",
  },
  components: {
    Menu: {
      padding: 24,
      colorBorderBg: "unset",
      activeBarHeight: 0,
      colorPrimaryBg: "unset",
      itemColor: "#49497A",
      itemHoverColor: "grey",
      itemSelectedColor: "#161633",
      itemSelectedBg: "#E6E6F5",
      itemBorderRadius: 20,
      itemActiveBg: "unset",
      itemHoverBg: "unset",
      itemBg: "unset",
      itemMarginBlock: 8,
      itemMarginInline: 0,
    },
    Layout: {
      colorBgLayout: "#F0EFEC",
    },
    Button: {
      colorText: "#2E2E48",
      colorBorder: "#F0BC9A",
      primaryColor: "#2E2E48",
      defaultBg: "#F3F3F3",
      borderRadiusLG: 20,
      contentFontSizeLG: 14,
      dangerColor: "#2E2E48",
      defaultHoverColor: "#161633",
      textHoverBg: "transparent",
    },
    Select: {
      selectorBg: "#F3F3F3",
      colorBorder: "none",
      borderRadiusLG: 20,
      fontSize: 14,
      optionActiveBg: "#E6E6F5",
      optionSelectedBg: "#E6E6F5",
      colorText: "#71717A",
      optionSelectedColor: "#161633",
      fontWeightStrong: 500,
    },
    Card: {
      colorBgContainer: "#F3F3F3",
      borderRadiusLG: 0,
      paddingLG: 0,
    },
    Table: {
      headerBg: "#EBEBF5",
      headerBorderRadius: 1,
      headerColor: "#71717A",
      rowHoverBg: "#E6E6F5",
    },
    Modal: {
      headerBg: "#F3F3F3",
      footerBg: "#F3F3F3",
      borderRadiusLG: 1,
      contentBg: "#F0EFEC",
    },
    Dropdown: {
      controlItemBgHover: "#E6E6F5",
    },
    Pagination: {
      borderRadius: 20,
      itemActiveBg: "#596184",
      itemBg: "transparent",
      colorPrimary: "#F3F3F3",
      colorPrimaryHover: "none",
    },
  },
}

export default theme
