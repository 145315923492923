import { App } from "antd"

export const useCustomNotification = () => {
  const { notification } = App.useApp()

  const openNotification = (description: string, error = false) => {
    notification.info({
      message: "",
      description,
      placement: "bottom",
      style: {
        background: error ? "#B83C33" : "#596184",
        borderRadius: "10px",
        padding: "16px 24px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "nowrap",
        fontSize: "16px",
        fontWeight: 600,
        width: "100%",
        height: "56px",
      },
      closable: false,
      duration: 3,
    })
  }

  return { openNotification }
}
