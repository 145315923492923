import { Layout as SignLayout } from "antd"
import { FC, Suspense } from "react"
import { Outlet } from "react-router-dom"

import "./index.less"

const AuthLayout: FC = () => (
  <SignLayout className={"auth-layout"}>
    <SignLayout.Content className={"auth-layout-content"}>
      <Suspense>
        <Outlet />
      </Suspense>
    </SignLayout.Content>
  </SignLayout>
)

export default AuthLayout
