import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { App as AntApp, ConfigProvider } from "antd"
import { createContext, memo, useContext, type FC, type PropsWithChildren } from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import introspection from "src/graphql"
import routes from "src/pages"
import theme from "src/themes"
import { version } from "../../package.json"

import { VideoProvider } from "src/context/VideoContext"
import NoConnection from "../components/NoConnection"
import "./index.less"

type ContextProps = {
  app: { version: string }
}

const app: ContextProps["app"] = { version }

const Context = createContext({ app })

const ContextProvider: FC<PropsWithChildren<ContextProps>> = ({ children, ...props }) => {
  return <Context.Provider value={{ ...props }}>{children}</Context.Provider>
}

export const useApp: () => ContextProps = () => useContext(Context)

const httpLink = createHttpLink({
  uri: `${import.meta.env.WEBSITE_API_URL ?? "/graphql"}`,
  credentials: "same-origin",
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("jwt")
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  connectToDevTools: import.meta.env.DEV,
  queryDeduplication: true,
  assumeImmutableResults: true,
  cache: new InMemoryCache({
    resultCaching: import.meta.env.PROD,
    possibleTypes: introspection.possibleTypes,
  }),
})

const router = createBrowserRouter(routes)

const App: FC = memo(() => (
  <ApolloProvider client={client}>
    <ContextProvider app={app}>
      <ConfigProvider theme={theme}>
        <VideoProvider>
          <AntApp>
            <NoConnection>
              <RouterProvider router={router} />
            </NoConnection>
          </AntApp>
        </VideoProvider>
      </ConfigProvider>
    </ContextProvider>
  </ApolloProvider>
))

export default App
