import { LoadingOutlined } from "@ant-design/icons"
import { Spin, SpinProps } from "antd"
import { FC } from "react"

const Loader: FC<SpinProps> = props => (
  <Spin
    indicator={
      <LoadingOutlined
        style={{
          fontSize: {
            small: 12,
            default: 24,
            large: 48,
          }[(props.size as SpinProps["size"]) ?? "default"],
          color: "black",
        }}
        spin
      />
    }
    {...props}
  />
)

export { Loader }
