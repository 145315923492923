import { FC, useEffect, useState } from "react"

import { Col, Flex, Menu, Row, type MenuProps, Avatar, Progress } from "antd"
import { NavLink, useLocation } from "react-router-dom"

import { Loader } from "src/components/Loader"
import { useLayoutQuery, useMeQuery } from "src/graphql"

import {ReactComponent as Note} from 'src/assets/icons/note.svg'
import {ReactComponent as User} from 'src/assets/icons/user.svg'
import {ReactComponent as Settings} from 'src/assets/icons/setting.svg'

import "./SiderNavigation.less"
import { UserOutlined } from "@ant-design/icons"

type MenuItem = Required<MenuProps>["items"][number]

const SiderNavigation: FC = () => {
  const { pathname } = useLocation()
  const { data, loading } = useLayoutQuery()
  const personalData = useMeQuery()?.data?.me
  const [profileCTA, setProfileCTA] = useState<boolean>(false)
  const profileCompleteness = !personalData?.profile?.data?.attributes?.name && !personalData?.profile?.data?.attributes?.avatar?.data ? 50 : 75
  const userRole = useMeQuery()?.data?.me?.role?.data?.attributes?.name
  const initials = useMeQuery()?.data?.me?.profile?.data?.attributes?.name?.split(' ').map(it => it[0]).join('').toUpperCase()
  useEffect(() => {
      setProfileCTA(!personalData?.profile?.data?.attributes?.name || !personalData?.profile?.data?.attributes?.avatar?.data?.id)
    }, [personalData]
  )

  if (loading) {
    return <Loader />
  }

  const topItems = data?.layout?.data?.attributes?.siderMenu?.map(link => {

    let iconComponent = null;
    switch (link?.title) {
      case "Jobs":
        iconComponent = <Note />;
        break;
      case "Leads":
        iconComponent = <User />;
        break;
      case "Settings":
        iconComponent = <Settings />;
        break;
      default:
        break;
    }

    return (userRole === "Recruiter" && link?.title === "Settings") ? null
      : {
        key: link?.url,
        label: (
          <NavLink className={"sider-navigation-link"} to={link?.url ?? "/"}>
            {link?.title}
          </NavLink>
        ),
        icon: iconComponent

      } as MenuItem
  })



  return (
    <Flex className={"sider-navigation"} vertical justify={"space-between"} gap={24}>
      {topItems && (
        <Menu
          theme={"light"}
          mode={"vertical"}
          activeKey={pathname}
          selectedKeys={[pathname]}
          style={{ background: "unset" }}
          items={topItems}
        />
      )}
      <div>
        {profileCTA &&
          <div className={"profile-cta"}>
            <div className={"title"}>Profile Completeness</div>
            <div className={"description"}>
              {`Your profile is ${profileCompleteness}% filled, please add info to your profile.`}
            </div>
            <Progress percent={profileCompleteness} showInfo={false} strokeColor={"#F0BC9A"} trailColor={"#694F3E"} />
            <Flex gap={16} className={"buttons"}>
              <div className={"dismiss"} onClick={() => setProfileCTA(false)}>Dismiss</div>
              <NavLink to={'/profile'} className={"update"}>Update now</NavLink>
            </Flex>
          </div>}
        <Menu
          theme={"light"}
          mode={"vertical"}
          activeKey={pathname}
          selectedKeys={[pathname]}
          style={{ background: "unset" }}
          items={[
            {
              key: "/profile",
              label: (
                <NavLink className={"sider-navigation-link"} to={"/profile"}>
                  <Row gutter={16}>
                    <Col>
                      <Avatar icon={!initials ? <UserOutlined /> : null} src={personalData?.profile?.data?.attributes?.avatar?.data?.attributes?.url ?? ''} size={40}>{initials}</Avatar>
                    </Col>
                    <Col>
                      <div style={{fontWeight: 500, fontSize: "14px", lineHeight: "24px"}}>
                        {personalData?.profile?.data?.attributes?.name}
                      </div>
                      <div style={{fontWeight: 400, fontSize: "12px", lineHeight: "16px", color: "#71717A"}}>
                        {personalData?.role?.data?.attributes?.name}
                      </div>
                    </Col>
                  </Row>
                </NavLink>
              )
            },
          ]}
        />
      </div>
    </Flex>
  )
}
export { SiderNavigation }
