import { Form, Input, Modal } from "antd"
import { Dispatch, FC, SetStateAction } from "react"
import { useCreateInquiryMutation } from "src/graphql"
import { useVideoContext } from "../../context/VideoContext"
import { useCustomNotification } from "../../hooks/useCustomNotification"

const { Item } = Form

const InquireModal: FC<{ open: boolean; setIsOpen: Dispatch<SetStateAction<boolean>> }> = ({ open, setIsOpen }) => {
  const [sendInquiry] = useCreateInquiryMutation()
  const [form] = Form.useForm()
  const { leadId, jobId, leadEmail } = useVideoContext()
  const { openNotification } = useCustomNotification()

  const handleSend = (values: { message: string }) => {
    try {
      sendInquiry({
        variables: {
          input: {
            lead: leadId,
            job: jobId,
            message: values.message,
            url: `${import.meta.env.WEBSITE_URL}inquiry?lead=${leadId}&job=${jobId}`,
            email: leadEmail,
          },
        },
      }).then(() => {
        setIsOpen(false)
        openNotification("Your inquiry is sent")
      })
    } catch (e: any) {
      openNotification(e.message, true)
    }
  }

  return (
    <Modal
      open={open}
      className={"video-modal"}
      title={"Inquire details"}
      okText={"Send"}
      okButtonProps={{ shape: "round", size: "large" }}
      cancelButtonProps={{ shape: "round", size: "large" }}
      onCancel={() => setIsOpen(false)}
      onOk={() => form.submit()}
    >
      <Form layout={"vertical"} form={form} onFinish={handleSend}>
        <Item name={"message"} label={"Message"} style={{ marginBottom: 0 }}>
          <Input.TextArea style={{ marginTop: 8, marginBottom: 0 }} rows={6} />
        </Item>
      </Form>
    </Modal>
  )
}

export { InquireModal }
