import { FC, lazy } from "react"
import { RouteObject } from "react-router-dom"
import { Loader } from "src/components/Loader"

import DefaultLayout from "src/components/DefaultLayout"
import AuthLayout from "../components/AuthLayout"

const Home = lazy<FC>(() => import("./home"))
const Jobs = lazy<FC>(() => import("./jobs"))
const SignUp = lazy<FC>(() => import("./sign-up"))
const SignIn = lazy<FC>(() => import("./sign-in"))
const ForgotPassword = lazy<FC>(() => import("./forgot-password"))
const ResetPassword = lazy<FC>(() => import("./reset-password"))
const Profile = lazy<FC>(() => import("./profile"))
const JobDetails = lazy<FC>(() => import("./job-details"))
const Job = lazy<FC>(() => import("./job"))
const Leads = lazy<FC>(() => import("./leads"))
const Inquiry = lazy<FC>(() => import("./inquiry"))
const Settings = lazy<FC>(() => import("./settings"))
const NotFound = lazy<FC>(() => import("./404"))
const AuthCallBack = lazy<FC>(() => import("./auth-callback"))
const PrivacyPolicy = lazy<FC>(() => import("./privacy-policy"))
const TermsOfService = lazy<FC>(() => import("./terms-of-service"))

const routes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Home,
        path: "",
        index: true,
      },
      {
        path: "/jobs",
        children: [
          {
            Component: Jobs,
            index: true,
          },
          {
            path: "/jobs/job-details/:id",
            children: [
              {
                Component: JobDetails,
                index: true,
              },
            ],
          },
        ],
      },
      {
        Component: Leads,
        path: "/leads",
        index: true,
      },
      {
        Component: Settings,
        path: "/settings",
        index: true,
      },
      {
        Component: Profile,
        path: "/profile",
        index: true,
      },
    ],
  },
  {
    Component: AuthLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: SignUp,
        path: "/sign-up/:step",
        index: true,
      },
      {
        Component: SignIn,
        path: "/sign-in",
        index: true,
      },
      {
        Component: ForgotPassword,
        path: "/forgot-password",
        index: true,
      },
      {
        Component: ResetPassword,
        path: "/reset-password",
        index: true,
      },
      {
        Component: PrivacyPolicy,
        path: "/privacy-policy",
        index: true,
      },
      {
        Component: TermsOfService,
        path: "/terms-of-service",
        index: true,
      },
      {
        Component: Job,
        path: "/job",
        index: true,
      },
      {
        Component: Inquiry,
        path: "/inquiry",
        index: true,
      },
      {
        Component: AuthCallBack,
        path: "/connect/google/redirect",
        index: true,
      },
      {
        Component: NotFound,
        path: "*",
      },
    ],
  },
]

export default routes
