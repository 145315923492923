import { Layout as BaseLayout } from "antd"
import { FC, Suspense, useEffect } from "react"
import { Outlet } from "react-router-dom"
import { Sider } from "./Sider"

import { useLocation, useNavigate } from "react-router"
import { VideoModal } from "../VideoModal"
import "./index.less"

const Layout: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const authToken = localStorage.getItem("jwt")
    if (!authToken) {
      navigate("/sign-in")
    }
  }, [location.pathname])

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/jobs")
    }
  }, [location.pathname])

  return (
    <BaseLayout className={"default-layout"}>
      <BaseLayout.Sider width={256} className={"default-layout-sider"} theme={"light"}>
        <Sider />
      </BaseLayout.Sider>
      <BaseLayout.Content className={"default-layout-content"}>
        <Suspense>
          <Outlet />
          <VideoModal />
        </Suspense>
      </BaseLayout.Content>
    </BaseLayout>
  )
}

export default Layout
