import { Dispatch, FC, ReactNode, SetStateAction, createContext, useContext, useState } from "react"

interface VideoContextProps {
  children: ReactNode
}

export const VideoContext = createContext<{
  link: string
  setLink: Dispatch<SetStateAction<string>>
  isModalOpen: boolean
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  leadId: string
  setLeadId: Dispatch<SetStateAction<string>>
  leadEmail: string
  setLeadEmail: Dispatch<SetStateAction<string>>
  leadName: string
  setLeadName: Dispatch<SetStateAction<string>>
  jobId: string
  setJobId: Dispatch<SetStateAction<string>>
}>({
  link: "",
  setLink: () => null,
  isModalOpen: false,
  setIsModalOpen: () => null,
  leadId: "",
  setLeadId: () => null,
  leadEmail: "",
  setLeadEmail: () => null,
  leadName: "",
  setLeadName: () => null,
  jobId: "",
  setJobId: () => null,
})

const VideoProvider: FC<VideoContextProps> = ({ children }) => {
  const [link, setLink] = useState<string>("")
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [leadId, setLeadId] = useState<string>("")
  const [leadName, setLeadName] = useState<string>("")
  const [jobId, setJobId] = useState<string>("")
  const [leadEmail, setLeadEmail] = useState<string>("")

  const contextValue = {
    link,
    setLink,
    isModalOpen,
    setIsModalOpen,
    leadId,
    setLeadId,
    leadEmail,
    setLeadEmail,
    leadName,
    setLeadName,
    jobId,
    setJobId,
  }

  return <VideoContext.Provider value={contextValue}>{children}</VideoContext.Provider>
}

const useVideoContext = () => useContext(VideoContext)

export { VideoProvider, useVideoContext }
