import { Flex } from "antd"
import { FC } from "react"

import { Logo } from "../../Logo"
import { SiderNavigation } from "./ui/SiderNavigation"

export const Sider: FC = () => {
  return (
    <Flex vertical gap={24} style={{ height: "100%", padding: "24px 16px" }}>
      <Logo style={{ cursor: "pointer", margin: "15px 24px" }} />
      <SiderNavigation />
    </Flex>
  )
}
