import { Typography } from "antd"
import { TitleProps } from "antd/es/typography/Title"
import { FC } from "react"
import "./index.less"

import { useNavigate } from "react-router"

const Logo: FC<TitleProps> = props => {
  const navigate = useNavigate()

  return (
    <Typography onClick={() => navigate("/")} {...props} className={"logo"}>
      ByeByeCV
    </Typography>
  )
}

export { Logo }
